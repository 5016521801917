import { render, staticRenderFns } from "./Farm.vue?vue&type=template&id=adb0cbea&scoped=true&"
import script from "./Farm.vue?vue&type=script&lang=ts&"
export * from "./Farm.vue?vue&type=script&lang=ts&"
import style0 from "./Farm.vue?vue&type=style&index=0&id=adb0cbea&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "adb0cbea",
  null
  
)

export default component.exports
import QCard from 'quasar/src/components/card/QCard.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QSkeleton from 'quasar/src/components/skeleton/QSkeleton.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QExpansionItem from 'quasar/src/components/expansion-item/QExpansionItem.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import QBtnGroup from 'quasar/src/components/btn-group/QBtnGroup.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QCard,QItem,QItemSection,QItemLabel,QSkeleton,QSeparator,QCardSection,QBtn,QTooltip,QExpansionItem,QCardActions,QBtnGroup});
